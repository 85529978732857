import React from "react"
import Layout from "../../components/layout"

import newsPostStyles from "../../styles/templates/news-post.module.css"

import edad from "../../images/news/article-2/ED AD2.png"

const Article2Page = () => {
    return (
        <Layout location="news">
        <div className={ newsPostStyles.article }>
            <h1>TEAM ED is part of EDIMCF Corp.</h1>
            <h3>November 26, 2020</h3>
            <div className={ newsPostStyles.posterRow }>
                <img className={ newsPostStyles.poster } src={edad} alt=""/>
            </div>
            <p>An integrated marketing communications organization that provides marcom services to clients of all sizes, from different industries, to effectively communicate the client’s messages to the target audiences through the use of marketing communication tools.</p>
            <button onClick={() => {
                window.history.back()
            }}>Go Back</button>
        </div>
        </Layout>
    )
}

export default Article2Page